import React, { useState } from "react";
import EmailInput from "@Molecules/common/auth/emailInput";
import PasswordInput from "@Molecules/common/auth/passwordInput";
import { AuthenticationChallenge } from "@Types";
import { Auth } from "aws-amplify";
import { pgRewardsApi } from "@Libs";
import { Button, LegacyCard } from "@shopify/polaris";
import { useTranslation } from "next-i18next";
import SignInButton from "@Molecules/common/auth/signinButton";
import { useDispatch } from "react-redux";
import axios from "axios";
import createPersistedState from "use-persisted-state";
import useUserCountry from "../hooks/useUserCountry";
import useToast from "../hooks/usetoast";

const useCountryState = createPersistedState("country");
const useCountryLastModifiedState = createPersistedState("countryLastModified");

type Props = {
  ip: string;
  setChallengeName: (challengeName: AuthenticationChallenge) => void;
  setTimeBaseCode: (code: string) => void;
  setUser: (user: any) => void;
};

export default function SigninForm({
  setChallengeName,
  ip,
  setUser,
  setTimeBaseCode,
}: Props) {
  const [, setCountry] = useCountryState("");
  const [, setCountryLastModified] = useCountryLastModifiedState();
  const { isNotAllowed, country } = useUserCountry();
  const [toast, toggleToast] = useToast();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const { t } = useTranslation("login");

  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  const logOut = async () => {
    try {
      setIsLoading(true);
      await Auth.signOut({ global: true });
      window.localStorage.removeItem("userToken");
      dispatch(pgRewardsApi.util.resetApiState());
      await axios("/api/token/signout");
      deleteCookie("token");

      setIsLoading(false);

      setCountry("");
      setCountryLastModified("");

      toggleToast("Browser reset successfully");
    } catch (error) {
      setIsLoading(false);
      //  Error signing out");
    }
  };

  return (
    <div>
      <LegacyCard sectioned title={t("login_title")}>
        <EmailInput
          label={t("email_label")}
          email={email}
          setEmail={setEmail}
        />{" "}
        <br />
        <PasswordInput password={password} setPassword={setPassword} />
        <SignInButton
          email={email}
          setUser={setUser}
          ip={ip}
          password={password}
          isNotAllowed={isNotAllowed}
          setChallengeName={setChallengeName}
          setTimeBaseCode={setTimeBaseCode}
        />
      </LegacyCard>
      {isNotAllowed && (
        <div className="mt-4 mb-4">
          <LegacyCard sectioned>
            <p className="text-[red]">
              You're trying to log in from {country}, a restricted area. Our
              service is not available in your region.
            </p>
          </LegacyCard>
        </div>
      )}

      <div className="mt-4 mb-4">
        <LegacyCard sectioned>
          <div className="flex justify-between flex-wrap">
            <p>Reset Browser Setting</p>
            <Button loading={isLoading} onClick={logOut}>
              Reset
            </Button>
          </div>
        </LegacyCard>
      </div>

      {toast}
    </div>
  );
}
